import React from 'react';
import moment from 'moment';

class EnergyBalanceSVG extends React.Component {

    gridLineWidthPerGw = 3;

    componentDidMount() {
        window.plausible('pageview', { u: "https://digital.upminsterwindmill.org/windPowerToday/" });
    }

    getGwValueForEnergyType(energyTypeName) {
        var energyArrayItem = this.props.energyBalanceData.energySources.find(e => e.name === energyTypeName);
        return (Math.round(energyArrayItem.mw / 10) / 100);
    }

    getImportExportTextForInterconnector(interconnectorName) {
        var energyArrayItem = this.props.energyBalanceData.energySources.find(e => e.name === interconnectorName);
        if (energyArrayItem.mw < 0) { return "EXPORT"; }
        if (energyArrayItem.mw > 0) { return "IMPORT"; }
        return "";
    }


    getPercentValueForEnergyType(energyTypeName) {
        var energyArrayItem = this.props.energyBalanceData.energySources.find(e => e.name === energyTypeName);
        return (Math.round(energyArrayItem.mw / this.getGwValueForTotalConsumption()) / 10);
    }

    getGwValueForTotalConsumption() {
        var totalConsumptionMw = 0;
        this.props.energyBalanceData.energySources.forEach(e => { totalConsumptionMw += e.mw });
        return (Math.round(totalConsumptionMw / 10) / 100);
    }


    getGwValueForTotalGeneration() {
        var totalGenerationMw = 0;
        this.props.energyBalanceData.energySources.forEach(e => { if (e.generationOrInterconnector === "GENERATION") { totalGenerationMw += e.mw } });
        return (Math.round(totalGenerationMw / 10) / 100);
    }

    getGwValueForTotalExport() {
        var totalExportMw = 0;
        this.props.energyBalanceData.energySources.forEach(e => { if (e.generationOrInterconnector === "INTERCONNECTOR" && e.mw < 0) { totalExportMw -= e.mw } });
        return (Math.round(totalExportMw / 10) / 100);
    }

    getGwValueForTotalImport() {
        var totalImportMw = 0;
        this.props.energyBalanceData.energySources.forEach(e => { if (e.generationOrInterconnector === "INTERCONNECTOR" && e.mw > 0) { totalImportMw += e.mw } });
        return (Math.round(totalImportMw / 10) / 100);
    }


    render() {
        console.log("EnergyBalanceSVG.render()");
        return (
            <>
                <svg viewBox="0 0 1450 1150" style={{ backgroundColor: "#D4E4F1", fontFamily: "Roboto Condensed" }}>

                    {/* National Grid */}

                    <rect x="540" y="170" width="370" height="880" rx="10" style={{ stroke: "#0070F0", fill: "#D4E4F1", strokeWidth: this.getGwValueForTotalConsumption() * this.gridLineWidthPerGw }}></rect>

                    <text x="725" y="350" style={{ fontSize: 36, fill: "#000000", textAnchor: "middle", fontWeight: "bold" }}>UK NATIONAL GRID</text>
                    <text x="725" y="400" style={{ fontSize: 36, fill: "#000000", textAnchor: "middle", fontWeight: "bold" }}>LIVE</text>

                    <text x="725" y="900" style={{ fontSize: 26, fill: "#000000", textAnchor: "middle" }}>UK Homes and Businesses</text>
                    <text x="725" y="935" style={{ fontSize: 26, fill: "#000000", textAnchor: "middle" }}>Consumption: {this.getGwValueForTotalConsumption()} GW</text>

                    {/* UK Generation */}
                    <rect x="20" y="20" width="440" height="1100" rx="10" style={{ fill: "#176397" }}></rect>
                    <text x="240" y="70" style={{ fontSize: 32, fill: "#FFFFFF", textAnchor: "middle" }}>UK GENERATION</text>

                    {/* Renewables */}
                    <rect x="35" y="100" width="410" height="390" rx="10" style={{ fill: "#D4E4F1" }}></rect>
                    <text x="50" y="140" style={{ fontSize: 24, fill: "rgb(0,0,0)" }}>RENEWABLES</text>
                    <g name="wind">
                        <circle cx="90" cy="200" r="38" fill="white"></circle>
                        <rect x="90" y="176" width="330" height="48" rx="10" fill="white"></rect>
                        <image x="56" y="166" width="67" height="67" href="/img/energysources/wind.svg"></image>
                        <text x="140" y="210" style={{ fontWeight: "bold", fontSize: 24, fill: "rgb(0,0,0)" }}>Wind</text>
                        <text x="320" y="210" style={{ fontSize: 24, fill: "rgb(0,0,0)", textAnchor: "end" }}>{this.getGwValueForEnergyType("WIND")} GW</text>
                        <text x="325" y="210" style={{ fontSize: 18, fill: "rgb(0,0,0)", textAnchor: "begin" }}>({this.getPercentValueForEnergyType("WIND")}%)</text>
                        <line x1="400" y1="200" x2="550" y2="200" style={{ strokeWidth: this.getGwValueForEnergyType("WIND") * this.gridLineWidthPerGw, stroke: "#0070F0" }}></line>
                    </g>
                    <g name="solar">
                        <circle cx="90" cy="280" r="38" fill="white"></circle>
                        <rect x="90" y="256" width="330" height="48" rx="10" fill="white"></rect>
                        <image x="57" y="246" width="67" height="67" href="/img/energysources/solar.svg"></image>
                        <text x="140" y="290" style={{ fontWeight: "bold", fontSize: 24, fill: "rgb(0,0,0)" }}>Solar</text>
                        <text x="320" y="290" style={{ fontSize: 24, fill: "rgb(0,0,0)", textAnchor: "end" }}>{this.getGwValueForEnergyType("SOLAR")} GW</text>
                        <text x="325" y="290" style={{ fontSize: 18, fill: "rgb(0,0,0)", textAnchor: "begin" }}>({this.getPercentValueForEnergyType("SOLAR")}%)</text>
                        <line x1="400" y1="280" x2="550" y2="280" style={{ strokeWidth: this.getGwValueForEnergyType("SOLAR") * this.gridLineWidthPerGw, stroke: "#0070F0" }}></line>
                    </g>
                    <g name="biomass">
                        <circle cx="90" cy="360" r="38" fill="white"></circle>
                        <rect x="90" y="336" width="330" height="48" rx="10" fill="white"></rect>
                        <image x="56" y="324" width="68" height="68" href="/img/energysources/biomass.svg"></image>
                        <text x="140" y="370" style={{ fontWeight: "bold", fontSize: 24, fill: "rgb(0,0,0)" }}>Biomass</text>
                        <text x="320" y="370" style={{ fontSize: 24, fill: "rgb(0,0,0)", textAnchor: "end" }}>{this.getGwValueForEnergyType("BIOMASS")} GW</text>
                        <text x="325" y="370" style={{ fontSize: 18, fill: "rgb(0,0,0)", textAnchor: "begin" }}>({this.getPercentValueForEnergyType("BIOMASS")}%)</text>
                        <line x1="400" y1="360" x2="550" y2="360" style={{ strokeWidth: this.getGwValueForEnergyType("BIOMASS") * this.gridLineWidthPerGw, stroke: "#0070F0" }}></line>
                    </g>
                    <g name="hydro">
                        <circle cx="90" cy="440" r="38" fill="white"></circle>
                        <rect x="90" y="416" width="330" height="48" rx="10" fill="white"></rect>
                        <image x="57" y="407" width="66" height="66" href="/img/energysources/hydro.svg"></image>
                        <text x="140" y="450" style={{ fontWeight: "bold", fontSize: 24, fill: "rgb(0,0,0)" }}>Hydro</text>
                        <text x="320" y="450" style={{ fontSize: 24, fill: "rgb(0,0,0)", textAnchor: "end" }}>{Math.round((this.getGwValueForEnergyType("PUMPED_HYDRO") + this.getGwValueForEnergyType("NON_PUMPED_HYDRO")) * 100) / 100} GW</text>
                        <text x="325" y="450" style={{ fontSize: 18, fill: "rgb(0,0,0)", textAnchor: "begin" }}>({Math.round((this.getPercentValueForEnergyType("PUMPED_HYDRO") + this.getPercentValueForEnergyType("NON_PUMPED_HYDRO")) * 100) / 100}%)</text>
                        <line x1="400" y1="440" x2="550" y2="440" style={{ strokeWidth: (this.getGwValueForEnergyType("PUMPED_HYDRO") + this.getGwValueForEnergyType("NON_PUMPED_HYDRO")) * this.gridLineWidthPerGw, stroke: "#0070F0" }}></line>
                    </g>


                    {/* Fossil Fuels */}
                    <rect x="35" y="510" width="410" height="310" rx="10" style={{ fill: "#D4E4F1" }}></rect>
                    <text x="50" y="550" style={{ fontSize: 24, fill: "rgb(0,0,0)" }}>FOSSIL FUELS</text>
                    <g name="gas">
                        <circle cx="90" cy="610" r="38" fill="white"></circle>
                        <rect x="90" y="586" width="330" height="48" rx="10" fill="white"></rect>
                        <image x="57" y="577" width="66" height="66" href="/img/energysources/gas.svg"></image>
                        <text x="140" y="620" style={{ fontWeight: "bold", fontSize: 24, fill: "rgb(0,0,0)" }}>Gas</text>
                        <text x="320" y="620" style={{ fontSize: 24, fill: "rgb(0,0,0)", textAnchor: "end" }}>{this.getGwValueForEnergyType("GAS")} GW</text>
                        <text x="325" y="620" style={{ fontSize: 18, fill: "rgb(0,0,0)", textAnchor: "begin" }}>({this.getPercentValueForEnergyType("GAS")}%)</text>
                        <line x1="400" y1="610" x2="550" y2="610" style={{ strokeWidth: this.getGwValueForEnergyType("GAS") * this.gridLineWidthPerGw, stroke: "#0070F0" }}></line>
                    </g>
                    <g name="oil">
                        <circle cx="90" cy="690" r="38" fill="white"></circle>
                        <rect x="90" y="666" width="330" height="48" rx="10" fill="white"></rect>
                        <image x="57" y="657" width="66" height="66" href="/img/energysources/coal.svg"></image>
                        <text x="140" y="700" style={{ fontWeight: "bold", fontSize: 24, fill: "rgb(0,0,0)" }}>Coal</text>
                        <text x="320" y="700" style={{ fontSize: 24, fill: "rgb(0,0,0)", textAnchor: "end" }}>{this.getGwValueForEnergyType("COAL")} GW</text>
                        <text x="325" y="700" style={{ fontSize: 18, fill: "rgb(0,0,0)", textAnchor: "begin" }}>({this.getPercentValueForEnergyType("COAL")}%)</text>
                        <line x1="400" y1="690" x2="550" y2="690" style={{ strokeWidth: this.getGwValueForEnergyType("COAL") * this.gridLineWidthPerGw, stroke: "#0070F0" }}></line>
                    </g>
                    <g name="oil">
                        <circle cx="90" cy="770" r="38" fill="white"></circle>
                        <rect x="90" y="746" width="330" height="48" rx="10" fill="white"></rect>
                        <image x="57" y="737" width="66" height="66" href="/img/energysources/oil.svg"></image>
                        <text x="140" y="780" style={{ fontWeight: "bold", fontSize: 24, fill: "rgb(0,0,0)" }}>Oil</text>
                        <text x="320" y="780" style={{ fontSize: 24, fill: "rgb(0,0,0)", textAnchor: "end" }}>{this.getGwValueForEnergyType("OIL")} GW</text>
                        <text x="325" y="780" style={{ fontSize: 18, fill: "rgb(0,0,0)", textAnchor: "begin" }}>({this.getPercentValueForEnergyType("OIL")}%)</text>
                        <line x1="400" y1="770" x2="550" y2="770" style={{ strokeWidth: this.getGwValueForEnergyType("OIL") * this.gridLineWidthPerGw, stroke: "#0070F0" }}></line>
                    </g>

                    {/* Nuclear */}
                    <rect x="35" y="840" width="410" height="100" rx="10" style={{ fill: "#D4E4F1" }}></rect>
                    <g name="nuclear">
                        <circle cx="90" cy="890" r="38" fill="white"></circle>
                        <rect x="90" y="866" width="330" height="48" rx="10" fill="white"></rect>
                        <image x="57" y="857" width="66" height="66" href="/img/energysources/nuclear.svg"></image>
                        <text x="140" y="900" style={{ fontWeight: "bold", fontSize: 24, fill: "rgb(0,0,0)" }}>Nuclear</text>
                        <text x="320" y="900" style={{ fontSize: 24, fill: "rgb(0,0,0)", textAnchor: "end" }}>{this.getGwValueForEnergyType("NUCLEAR")} GW</text>
                        <text x="325" y="900" style={{ fontSize: 18, fill: "rgb(0,0,0)", textAnchor: "begin" }}>({this.getPercentValueForEnergyType("NUCLEAR")}%)</text>
                        <line x1="400" y1="890" x2="550" y2="890" style={{ strokeWidth: this.getGwValueForEnergyType("NUCLEAR") * this.gridLineWidthPerGw, stroke: "#0070F0" }}></line>
                    </g>


                    {/* Other */}
                    <rect x="35" y="960" width="410" height="100" rx="10" style={{ fill: "#D4E4F1" }}></rect>
                    <g name="other">
                        <circle cx="90" cy="1010" r="38" fill="white"></circle>
                        <rect x="90" y="986" width="330" height="48" rx="10" fill="white"></rect>
                        <text x="140" y="1020" style={{ fontWeight: "bold", fontSize: 24, fill: "rgb(0,0,0)" }}>Other</text>
                        <text x="320" y="1020" style={{ fontSize: 24, fill: "rgb(0,0,0)", textAnchor: "end" }}>{this.getGwValueForEnergyType("OTHER")} GW</text>
                        <text x="325" y="1020" style={{ fontSize: 18, fill: "rgb(0,0,0)", textAnchor: "begin" }}>({this.getPercentValueForEnergyType("OTHER")}%)</text>
                        <line x1="400" y1="1010" x2="550" y2="1010" style={{ strokeWidth: this.getGwValueForEnergyType("OTHER") * this.gridLineWidthPerGw, stroke: "#0070F0" }}></line>
                    </g>

                    {/* Total Generation */}
                    <text x="240" y="1100" style={{ fontSize: 26, fill: "#FFFFFF", textAnchor: "middle" }}>Total Generation: {this.getGwValueForTotalGeneration()} GW</text>

                    {/* Imports / Exports */}
                    <rect x="990" y="20" width="440" height="1100" rx="10" style={{ fill: "#176397" }}></rect>
                    <text x="1210" y="70" style={{ fontSize: 32, fill: "#FFFFFF", textAnchor: "middle" }}>IMPORTS &amp; EXPORTS</text>

                    <rect x="1015" y="200" width="395" height="820" rx="10" style={{ fill: "#D4E4F1" }}></rect>

                    <g name="france">
                        <rect x="1070" y="306" width="320" height="64" rx="10" fill="white"></rect>
                        <rect x="1030" y="300" width="80" height="62" rx="10" fill="white"></rect>
                        <image x="1037" y="297" width="68" height="68" href="/img/flags/france.svg"></image>
                        <text x="1120" y="340" style={{ fontWeight: "bold", fontSize: 24, fill: "rgb(0,0,0)" }}>France</text>
                        <text x="1330" y="340" style={{ fontSize: 24, fill: "rgb(0,0,0)", textAnchor: "end" }}>{Math.abs(this.getGwValueForEnergyType("FRANCE"))} GW</text>
                        <text x="1335" y="340" style={{ fontSize: 18, fill: "rgb(0,0,0)", textAnchor: "begin" }}>({Math.abs(this.getPercentValueForEnergyType("FRANCE"))}%)</text>
                        <text x="1330" y="360" style={{ fontSize: 14, fill: "rgb(0,0,0)", textAnchor: "end" }}>{this.getImportExportTextForInterconnector("FRANCE")}</text>
                        <line x1="900" y1="330" x2="1030" y2="330" style={{ strokeWidth: this.getGwValueForEnergyType("FRANCE") * this.gridLineWidthPerGw, stroke: "#0070F0" }}></line>
                    </g>

                    <g name="belgium">
                        <rect x="1070" y="406" width="320" height="64" rx="10" fill="white"></rect>
                        <rect x="1030" y="400" width="80" height="62" rx="10" fill="white"></rect>
                        <image x="1037" y="397" width="68" height="68" href="/img/flags/belgium.svg"></image>
                        <text x="1120" y="440" style={{ fontWeight: "bold", fontSize: 24, fill: "rgb(0,0,0)" }}>Belgium</text>
                        <text x="1330" y="440" style={{ fontSize: 24, fill: "rgb(0,0,0)", textAnchor: "end" }}>{Math.abs(this.getGwValueForEnergyType("BELGIUM"))} GW</text>
                        <text x="1335" y="440" style={{ fontSize: 18, fill: "rgb(0,0,0)", textAnchor: "begin" }}>({Math.abs(this.getPercentValueForEnergyType("BELGIUM"))}%)</text>
                        <text x="1330" y="460" style={{ fontSize: 14, fill: "rgb(0,0,0)", textAnchor: "end" }}>{this.getImportExportTextForInterconnector("BELGIUM")}</text>
                        <line x1="900" y1="430" x2="1030" y2="430" style={{ strokeWidth: this.getGwValueForEnergyType("BELGIUM") * this.gridLineWidthPerGw, stroke: "#0070F0" }}></line>
                    </g>

                    <g name="netherlands">
                        <rect x="1070" y="506" width="320" height="64" rx="10" fill="white"></rect>
                        <rect x="1030" y="500" width="80" height="62" rx="10" fill="white"></rect>
                        <image x="1037" y="497" width="68" height="68" href="/img/flags/netherlands.svg"></image>
                        <text x="1120" y="540" style={{ fontWeight: "bold", fontSize: 24, fill: "rgb(0,0,0)" }}>Netherlands</text>
                        <text x="1330" y="540" style={{ fontSize: 24, fill: "rgb(0,0,0)", textAnchor: "end" }}>{Math.abs(this.getGwValueForEnergyType("NETHERLANDS"))} GW</text>
                        <text x="1335" y="540" style={{ fontSize: 18, fill: "rgb(0,0,0)", textAnchor: "begin" }}>({Math.abs(this.getPercentValueForEnergyType("NETHERLANDS"))}%)</text>
                        <text x="1330" y="560" style={{ fontSize: 14, fill: "rgb(0,0,0)", textAnchor: "end" }}>{this.getImportExportTextForInterconnector("NETHERLANDS")}</text>
                        <line x1="900" y1="530" x2="1030" y2="530" style={{ strokeWidth: this.getGwValueForEnergyType("NETHERLANDS") * this.gridLineWidthPerGw, stroke: "#0070F0" }}></line>
                    </g>

                    <g name="norway">
                        <rect x="1070" y="606" width="320" height="64" rx="10" fill="white"></rect>
                        <rect x="1030" y="600" width="80" height="62" rx="10" fill="white"></rect>
                        <image x="1037" y="597" width="68" height="68" href="/img/flags/norway.svg"></image>
                        <text x="1120" y="640" style={{ fontWeight: "bold", fontSize: 24, fill: "rgb(0,0,0)" }}>Norway</text>
                        <text x="1330" y="640" style={{ fontSize: 24, fill: "rgb(0,0,0)", textAnchor: "end" }}>{Math.abs(this.getGwValueForEnergyType("NORWAY"))} GW</text>
                        <text x="1335" y="640" style={{ fontSize: 18, fill: "rgb(0,0,0)", textAnchor: "begin" }}>({Math.abs(this.getPercentValueForEnergyType("NORWAY"))}%)</text>
                        <text x="1330" y="660" style={{ fontSize: 14, fill: "rgb(0,0,0)", textAnchor: "end" }}>{this.getImportExportTextForInterconnector("NORWAY")}</text>
                        <line x1="900" y1="630" x2="1030" y2="630" style={{ strokeWidth: this.getGwValueForEnergyType("NORWAY") * this.gridLineWidthPerGw, stroke: "#0070F0" }}></line>
                    </g>

                    <g name="dk">
                        <rect x="1070" y="706" width="320" height="64" rx="10" fill="white"></rect>
                        <rect x="1030" y="700" width="80" height="62" rx="10" fill="white"></rect>
                        <image x="1037" y="697" width="68" height="68" href="/img/flags/dk.svg"></image>
                        <text x="1120" y="740" style={{ fontWeight: "bold", fontSize: 24, fill: "rgb(0,0,0)" }}>Denmark</text>
                        <text x="1330" y="740" style={{ fontSize: 24, fill: "rgb(0,0,0)", textAnchor: "end" }}>{Math.abs(this.getGwValueForEnergyType("DENMARK"))} GW</text>
                        <text x="1335" y="740" style={{ fontSize: 18, fill: "rgb(0,0,0)", textAnchor: "begin" }}>({Math.abs(this.getPercentValueForEnergyType("DENMARK"))}%)</text>
                        <text x="1330" y="760" style={{ fontSize: 14, fill: "rgb(0,0,0)", textAnchor: "end" }}>{this.getImportExportTextForInterconnector("DENMARK")}</text>
                        <line x1="900" y1="730" x2="1030" y2="730" style={{ strokeWidth: this.getGwValueForEnergyType("DENMARK") * this.gridLineWidthPerGw, stroke: "#0070F0" }}></line>
                    </g>


                    <g name="ni">
                        <rect x="1070" y="806" width="320" height="64" rx="10" fill="white"></rect>
                        <rect x="1030" y="800" width="80" height="62" rx="10" fill="white"></rect>
                        <image x="1037" y="797" width="68" height="68" href="/img/flags/uk.svg"></image>
                        <text x="1120" y="840" style={{ fontWeight: "bold", fontSize: 24, fill: "rgb(0,0,0)" }}>N. Ireland</text>
                        <text x="1330" y="840" style={{ fontSize: 24, fill: "rgb(0,0,0)", textAnchor: "end" }}>{Math.abs(this.getGwValueForEnergyType("NORTHERN_IRELAND"))} GW</text>
                        <text x="1335" y="840" style={{ fontSize: 18, fill: "rgb(0,0,0)", textAnchor: "begin" }}>({Math.abs(this.getPercentValueForEnergyType("NORTHERN_IRELAND"))}%)</text>
                        <text x="1330" y="860" style={{ fontSize: 14, fill: "rgb(0,0,0)", textAnchor: "end" }}>{this.getImportExportTextForInterconnector("NORTHERN_IRELAND")}</text>
                        <line x1="900" y1="830" x2="1030" y2="830" style={{ strokeWidth: this.getGwValueForEnergyType("NORTHERN_IRELAND") * this.gridLineWidthPerGw, stroke: "#0070F0" }}></line>
                    </g>

                    <g name="roi">
                        <rect x="1070" y="906" width="320" height="64" rx="10" fill="white"></rect>
                        <rect x="1030" y="900" width="80" height="62" rx="10" fill="white"></rect>
                        <image x="1037" y="897" width="68" height="68" href="/img/flags/roi.svg"></image>
                        <text x="1120" y="940" style={{ fontWeight: "bold", fontSize: 24, fill: "rgb(0,0,0)" }}>Ireland</text>
                        <text x="1330" y="940" style={{ fontSize: 24, fill: "rgb(0,0,0)", textAnchor: "end" }}>{Math.abs(this.getGwValueForEnergyType("REPUBLIC_OF_IRELAND"))} GW</text>
                        <text x="1335" y="940" style={{ fontSize: 18, fill: "rgb(0,0,0)", textAnchor: "begin" }}>({Math.abs(this.getPercentValueForEnergyType("REPUBLIC_OF_IRELAND"))}%)</text>
                        <text x="1330" y="960" style={{ fontSize: 14, fill: "rgb(0,0,0)", textAnchor: "end" }}>{this.getImportExportTextForInterconnector("REPUBLIC_OF_IRELAND")}</text>
                        <line x1="900" y1="930" x2="1030" y2="930" style={{ strokeWidth: this.getGwValueForEnergyType("REPUBLIC_OF_IRELAND") * this.gridLineWidthPerGw, stroke: "#0070F0" }}></line>
                    </g>


                    {this.getGwValueForTotalExport() - this.getGwValueForTotalImport() > 0 &&
                        <text x="1210" y="1100" style={{ fontSize: 26, fill: "#FFFFFF", textAnchor: "middle" }}>Net Export: {Math.round((this.getGwValueForTotalExport() - this.getGwValueForTotalImport()) * 100) / 100} GW</text>
                    }

                    {this.getGwValueForTotalExport() - this.getGwValueForTotalImport() <= 0 &&
                        <text x="1190" y="1100" style={{ fontSize: 26, fill: "#FFFFFF", textAnchor: "middle" }}>Net Import: {Math.round((this.getGwValueForTotalImport() - this.getGwValueForTotalExport()) * 100) / 100} GW</text>
                    }


                    <text x="1430" y="1145" style={{ fontSize: 18, fill: "rgb(0,0,0)", textAnchor: "end" }}>Data provided by Elexon and University of Sheffield at {moment(this.props.energyBalanceData.elexonRawData[0].publishTime).format("DD/MM/yyyy HH:mm:ss")}</text>



                </svg>
            </>
        )
    }
}

export { EnergyBalanceSVG }
